@if (form) {
  <div class="px-4 pb-4" [formGroup]="form">
    <div class="row">
      <!-- isDisability -->
      <div class="col-sm-6 col-lg-4">
        <app-dropdown label="FIELDS.HAVE_DISABILITY" formControlName="isDisability" [options]="yesNoOptions" />
      </div>

      <!-- disabilityAttachment -->
      @if (isDisabilityAttachmentEnabled) {
        <div class="col-sm-6 col-lg-4">
          <app-attachment
            label="FIELDS.DISABILITY_PROOF"
            formControlName="disabilityAttachment"
            type="imageDocument"
            [allowedTypes]="allowedTypes"
          />
        </div>
      }

      <!-- membershipCardPlanId -->
      @if (membershipCardPlans) {
        <div class="col-sm-6 col-lg-4">
          <app-dropdown label="FIELDS.SUBSCRIPTION_FOR" formControlName="membershipCardPlanId" [options]="membershipCardPlans()!" />
        </div>
      }

      <!-- employmentType -->
      <div class="col-sm-6 col-lg-4">
        <app-dropdown label="FIELDS.JOB_STATUS" formControlName="employmentType" [options]="employmentTypes()!" />
      </div>

      <!-- residenceType -->
      <div class="col-sm-6 col-lg-4">
        <app-dropdown label="FIELDS.ARE_YOU" formControlName="residenceType" [options]="residenceTypes()!" />
      </div>
    </div>

    <hr />
    <!-- personal details -->
    <div class="row row-gap-4 mt-4" formGroupName="user">
      <div class="col-12">
        <h3 class="m-0 font-16 fw-bold text-black pe-4 pb-2 border-2 border-bottom d-flex w-max">
          {{ "FRIENDS_OF_OMAN_MUSEUM.PERSONAL_DETAILS" | translate }}
        </h3>
      </div>

      <!-- nameEn -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="USER.NAME_EN" formControlName="nameEn" [readonly]="true" />
      </div>

      <!-- nameAr -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="USER.NAME_AR" formControlName="nameAr" [readonly]="true" />
      </div>

      <!-- userName -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.USERNAME" formControlName="userName" [readonly]="true" />
      </div>

      <!-- dateOfBirth -->
      <div class="col-sm-6 col-lg-4">
        <app-datepicker label="FIELDS.DATE_OF_BIRTH" formControlName="dateOfBirth" [readonly]="true" />
      </div>

      <!-- email -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.EMAIL" formControlName="email" contentType="email" [readonly]="true" />
      </div>

      <!-- phone -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.PHONE" formControlName="phone" contentType="number" [readonly]="true" />
      </div>

      <!-- countryId -->
      <div class="col-sm-6 col-lg-4">
        <app-dropdown label="FIELDS.COUNTRY" formControlName="countryId" [options]="countries()!" [readonly]="true" />
      </div>

      <!-- gender -->
      <div class="col-sm-6 col-lg-4">
        <app-dropdown label="FIELDS.GENDER" formControlName="gender" [options]="genders()!" [readonly]="true" />
      </div>
    </div>

    <!-- work place -->
    <div class="row row-gap-4 mt-4" formGroupName="workPlaceDetails">
      <div class="col-12">
        <h3 class="m-0 font-16 fw-bold text-black pe-4 pb-2 border-2 border-bottom d-flex w-max">
          {{ "FRIENDS_OF_OMAN_MUSEUM.WORK_PLACE" | translate }}
        </h3>
      </div>

      <!-- companyName -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.COMPANY_NAME" formControlName="companyName" />
      </div>

      <!-- address -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.COMPANY_ADDRESS" formControlName="address" />
      </div>

      <!-- jobTitle -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.JOB_TITLE" formControlName="jobTitle" />
      </div>

      <!-- comments -->
      <div class="col-12">
        <app-input-text label="FIELDS.COMMENTS" formControlName="comments" inputType="textarea" />
      </div>
    </div>

    <!-- Permanent Address -->
    <div class="row row-gap-4 mt-4" formGroupName="permanentAddressDetails">
      <div class="col-12">
        <h3 class="m-0 font-16 fw-bold text-black pe-4 pb-2 border-2 border-bottom d-flex w-max">
          {{ "FRIENDS_OF_OMAN_MUSEUM.PERMANENT_ADDRESS" | translate }}
        </h3>
      </div>

      <!-- zipCode -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.ZIP_CODE" formControlName="zipCode" contentType="number" />
      </div>

      <!-- postalCode -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.POSTAL_CODE" formControlName="postalCode" contentType="number" />
      </div>

      <!-- nationalId -->
      <div class="col-sm-6 col-lg-4">
        <app-input-text label="FIELDS.NATIONAL_ID" formControlName="nationalId" contentType="number" />
      </div>

      <!-- countryId -->
      <div class="col-md-6 col-lg-4">
        <app-dropdown label="FIELDS.COUNTRY" formControlName="countryId" [options]="countries()!" (selected)="getGovernorates($event)" />
      </div>

      <!-- governorateId -->
      <div class="col-md-6 col-lg-4">
        <app-dropdown
          label="FIELDS.GOVERNORATE"
          formControlName="governorateId"
          [options]="governorates()!"
          (selected)="getStates($event)"
        />
      </div>

      <!-- stateId -->
      <div class="col-md-6 col-lg-4">
        <app-dropdown label="FIELDS.STATE" formControlName="stateId" [options]="states()!" />
      </div>
    </div>

    <!-- nationalIdCardPhoto -->
    <hr class="w-100" />
    <div class="row">
      <div class="col-sm-6 col-lg-4">
        <app-attachment
          label="FIELDS.ATTACH_NATIONAL_ID_CARD_PHOTO"
          formControlName="nationalIdCardPhoto"
          type="image"
          [allowedTypes]="cardCoverAllowedTypes"
        />
      </div>
    </div>

    <!-- acknowledgement -->
    <div class="mt-3">
      <app-checkbox label="FRIENDS_OF_OMAN_MUSEUM.ACKNOWLEDGEMENT" formControlName="acknowledgement" />
    </div>
  </div>
}
