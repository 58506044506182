export enum ParentServiceTypes {
  TOUR = 1,
  EVENT = 2,
}

export enum SpecialReservation {
  GROUPDISCOUNT = 1,
  TICKETDISCOUNT,
  FREETICKET,
}

export enum ParentServiceCode {
  TOUR = "TOUR",
  EVENT = "EVENT",
}

export enum TourServicesCodes {
  EntranceTicket = "EntranceTicket",
  FreeEntry = "FreeEntry",
  GroupOffers = "GroupOffers",
  SchoolUniversityVisits = "SchoolUniversityVisits",
  Packages = "Packages",
  GuidedTours = "GuidedTours",
  TourismCompanies = "TourismCompanies",
  OfficialInstitutions = "OfficialInstitutions",
  FriendsOfOmanMuseum = "FriendsOfOmanMuseum",
  Products = "Products",
}

export enum EventServicesCodes {
  ExhibitionsAndEvents = "ExhibitionsAndEvents",
}

export type ServiceCodes = TourServicesCodes | EventServicesCodes;
