import { first } from "rxjs";
import { MatError } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation, forwardRef } from "@angular/core";

import { ButtonComponent } from "../button/button.component";
import { ValidationHandlerPipe } from "../../pipes";
import { ReactiveFormsBaseComponent } from "../../base-components";

@Component({
  selector: "app-input-count",
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, ButtonComponent, MatError, ValidationHandlerPipe],
  templateUrl: "./input-count.component.html",
  styleUrl: "./input-count.component.scss",
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCountComponent),
      multi: true,
    },
  ],
})
export class InputCountComponent extends ReactiveFormsBaseComponent {
  @Input() override formControlName!: string;
  @Input() count = 0;
  @Input() label!: string;
  @Input() withInput = true;
  @Input() readonly = false;

  @Output() inputValue = new EventEmitter<number>();

  get min() {
    if (this.control?.validator?.(this.control)) return this.control?.validator(this.control)?.["min"]?.min ?? 0;

    return 0;
  }

  get max() {
    if (this.control?.validator?.(this.control)) return this.control?.validator(this.control)?.["max"]?.max ?? 1000;

    return 1000;
  }

  get disabled() {
    return this.readonly || this.control?.disabled;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.control?.valueChanges.pipe(first()).subscribe((value) => (this.count = value));
  }

  plus() {
    if (this.disabled) return;
    if (this.value) this.count = this.value;
    if (this.count >= this.max) return;
    if (this.control?.value === 0) this.count = 0;

    this.count += 1;
    this.control?.setValue(this.count);
    this.inputValue.emit(this.count);
  }

  minus() {
    if (this.disabled) return;
    if (this.value) this.count = this.value;
    if (this.count <= this.min) return;
    if (this.control?.value === 0) this.count = 0;

    if (this.count !== 0) {
      this.count -= 1;
      this.control?.setValue(this.count);
      this.inputValue.emit(this.count);
    }
  }

  onKeyPress(event: KeyboardEvent): void {
    if (["e", "E", "+", "-", "."].includes(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener("paste", ["$event"]) onPaste(event: ClipboardEvent): void {
    if (event.clipboardData?.getData("text/plain").toLowerCase().includes("e")) {
      event.preventDefault();
    }
  }
}
