import { AbstractControl, ValidatorFn } from "@angular/forms";

const RegexValidations = {
  idNumber: /^[1-2]\d{9}$/i,
  mobileNumber: /^(009685|9685|\+9685|05|5)(0|1|3|4|5|6|7|8|9)([0-9]{7})$/,
  charactersOnly: /^[a-zA-Z\u0600-\u06FF\s]+$/,
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  arabicCharactersOnly: /^[-_() \u0621-\u064A 0-9]+$/i,
  englishCharactersOnly: /^[-_() a-zA-Z 0-9]+$/i,
  positiveDecimal: /^(?!-)[0-9]+(\.[0-9]+)?$/,
};

export const customValidations = {
  idNumber: (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;

      if (!RegexValidations.idNumber.test(value)) {
        return { id_number: true };
      }

      return null;
    };
  },
  email: (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;

      if (!RegexValidations.email.test(value)) {
        return { email: true };
      }

      return null;
    };
  },
  mobileNumber: (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;

      if (!RegexValidations.mobileNumber.test(value)) {
        return { invalid_mobile_number: true };
      }

      return null;
    };
  },
  charactersOnly: (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;

      if (!RegexValidations.charactersOnly.test(value)) {
        return { characters_only: true };
      }

      return null;
    };
  },
  arabicCharactersOnly: (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;

      if (!RegexValidations.arabicCharactersOnly.test(value)) {
        return { arabic_characters_only: true };
      }

      return null;
    };
  },
  englishCharactersOnly: (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;

      if (!RegexValidations.englishCharactersOnly.test(value)) {
        return { english_characters_only: true };
      }

      return null;
    };
  },
  positiveDecimalNumbersOnly: (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (!RegexValidations.positiveDecimal.test(value)) {
        return { positive_decimal: true };
      }
      return null;
    };
  },
};
