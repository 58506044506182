import { Injectable } from "@angular/core";

import { MembershipPayload, Membership } from "../../interfaces";

import { HttpService, HttpServiceBaseCRUD, GetPagedBody, GetPagedResponse } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class MembershipsService extends HttpService implements HttpServiceBaseCRUD {
  protected override get baseUrl(): string {
    return "memberships/";
  }

  add(body: MembershipPayload) {
    return this.post<Membership>({ APIName: "add", body, showAlert: true });
  }

  update(body: MembershipPayload) {
    return this.put<Membership>({ APIName: "update", body, showAlert: true });
  }

  remove(id: string) {
    return this.delete<boolean>({ APIName: `deletesoft/${id}`, showAlert: true });
  }

  getById(id: string) {
    return this.get<Membership>({ APIName: `get-id/${id}` });
  }

  getAll() {
    return this.get<Membership[]>({ APIName: "getall" });
  }

  getPaged(body: GetPagedBody<Membership>) {
    return this.post<GetPagedResponse<Membership[]>>({ APIName: "getpaged", body });
  }
}
